import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { NextPage, GetServerSidePropsContext, NextApiRequest } from 'next';
import { IncomingHttpHeaders } from 'node:http';
import { ParsedUrlQuery } from 'node:querystring';

import { getAuthCredentials, getCurrentUserAsync } from '~/common/authentication';
import {
  FEATURE_GATE_DISABLE_PARAM,
  FEATURE_GATE_ENABLE_PARAM,
  FeatureGateQueryParams,
} from '~/common/gating/FeatureGateOverrides';
import { getParamString } from '~/common/getParamString';
import { createClient } from '~/common/graphql-client';
import { redirect } from '~/common/utilities';
import { AppDevDomainNameDocument } from '~/graphql/queries/AppDevDomainName.query.generated';
import {
  AppDevDomainNameQuery,
  CurrentUserQuery,
  ExperimentationQuery,
} from '~/graphql/types.generated';
import { UniversalHttpHeaders } from '~/types';
import {
  isAllowedAppRedirectUri,
  isExpoRedirectUri,
  isInternalRedirectUri,
} from '~/ui/components/authentication/helpers';

export type PageOptions = {
  authRequired?: boolean;
};

export type GlobalProps = {
  currentUser: NonNullable<CurrentUserQuery['meUserActor']> | null;
  params: { [param: string]: string };
  accountName: string | null;
  isYou: boolean;
  pathname: string;
  currentDateAsString: string;
  userAgent?: string;
  headers?: UniversalHttpHeaders;
  isServerSideRendered?: boolean;
  cookies?: NextApiRequest['cookies'];
  isHostingConfigured: boolean | null;
};

export type PageProps = GlobalProps & {
  initialApolloState?: NormalizedCacheObject;
  experimentationConfig?: ExperimentationQuery | null;
  resolvedUrl?: string;
};

export type LoggedInProps = PageProps & {
  accountName: NonNullable<PageProps['accountName']>;
  currentUser: NonNullable<PageProps['currentUser']>;
};

export type HostingProps = {
  isHostingConfigured: boolean;
};

export function getAccountName(
  currentUser?: NonNullable<CurrentUserQuery['meUserActor']> | null,
  params?: ParsedUrlQuery
) {
  if (params?.account && !Array.isArray(params.account)) {
    return params.account;
  }

  // return primary account if available
  if (currentUser?.primaryAccount) {
    return currentUser.primaryAccount.name;
  }

  return null;
}

export function getGlobalProps({
  ctx,
  headers,
  currentUser,
  isHostingConfigured,
}: {
  ctx: GetServerSidePropsContext;
  headers: IncomingHttpHeaders;
  currentUser: NonNullable<CurrentUserQuery['meUserActor']> | null;
  isHostingConfigured: boolean | null;
}): GlobalProps {
  const params = ctx.query ?? {};
  const formattedParams = Object.keys(params).reduce(
    (acc, paramKey) => ({
      ...acc,
      [paramKey]: getParamString(params[paramKey]),
    }),
    {}
  );
  const pathname = ctx.req?.url ?? ctx.resolvedUrl;
  const userAgent = headers ? headers['user-agent'] : navigator.userAgent;

  const accountName = getAccountName(currentUser, params);
  const isYou = currentUser?.username && accountName ? currentUser.username === accountName : false;

  const currentDateAsString = new Date().toISOString();

  return {
    currentUser,
    params: formattedParams,
    accountName,
    isYou,
    pathname,
    userAgent,
    headers: getUniversalHttpHeaders(headers),
    currentDateAsString,
    isServerSideRendered: true,
    cookies: ctx.req.cookies,
    isHostingConfigured,
  };
}

export function customRedirects({
  ctx,
  currentUser,
  pageOptions,
}: {
  ctx: GetServerSidePropsContext;
  currentUser: NonNullable<CurrentUserQuery['meUserActor']> | null;
  pageOptions?: PageOptions;
}) {
  const params = ctx.query ?? {};

  if (pageOptions?.authRequired && !currentUser) {
    return redirect(`/login?redirect_uri=${encodeURIComponent(ctx?.req?.url ?? '')}`, ctx);
  }

  if (
    (ctx.resolvedUrl.startsWith('/login') ||
      ctx.resolvedUrl.startsWith('/signup') ||
      ctx.resolvedUrl.startsWith('/sso-login') ||
      ctx.resolvedUrl.startsWith('/sso-signup')) &&
    currentUser &&
    !params.prompt
  ) {
    const rawAppRedirectUri = params.app_redirect_uri;
    const appRedirectUri = rawAppRedirectUri
      ? Array.isArray(rawAppRedirectUri)
        ? decodeURIComponent(rawAppRedirectUri[0])
        : decodeURIComponent(rawAppRedirectUri)
      : undefined;

    const rawRedirectUri = params.redirect_uri;
    const redirectUri = rawRedirectUri
      ? Array.isArray(rawRedirectUri)
        ? decodeURIComponent(rawRedirectUri[0])
        : decodeURIComponent(rawRedirectUri)
      : undefined;

    if (params.confirm_account) {
      const searchParams = new URLSearchParams(
        ctx.resolvedUrl?.substring(ctx.resolvedUrl.indexOf('?') + 1)
      );
      searchParams.delete('confirm_account');

      return redirect(
        `/auth/confirm-account?origin=${encodeURIComponent(
          ctx.resolvedUrl?.substring(0, ctx.resolvedUrl.indexOf('?') + 1) + searchParams.toString()
        )}`,
        ctx
      );
    }

    if (appRedirectUri && isAllowedAppRedirectUri(appRedirectUri)) {
      const parsedHeaders = getAuthCredentials(ctx.req?.headers);
      return redirect(
        `${appRedirectUri}?username_or_email=${encodeURIComponent(
          currentUser.username
        )}&session_secret=${encodeURIComponent(JSON.stringify(parsedHeaders?.sessionSecret))}`,
        ctx
      );
    } else if (
      redirectUri &&
      (isInternalRedirectUri(redirectUri) || isExpoRedirectUri(redirectUri))
    ) {
      return redirect(redirectUri, ctx);
    } else {
      return redirect('/', ctx);
    }
  }

  if (currentUser?.preferences.selectedAccountName && ctx.resolvedUrl === '/') {
    redirect(`/accounts/${currentUser.preferences.selectedAccountName}`, ctx);
  }
}

export async function getServerSidePropsFor(
  ctx: GetServerSidePropsContext,
  Page: NextPage & {
    pageOptions: PageOptions;
  } & any
) {
  const headers = ctx?.req?.headers ?? {};
  const apolloClient = createApolloClient(ctx);
  const currentUserQuery = await getCurrentUserAsync({
    apolloClient,
    headers,
  });

  const currentUser = currentUserQuery?.currentUser ?? null;
  const experimentationConfig = currentUserQuery?.experimentation ?? null;

  const isHostingConfigured = await getIsHostingConfiguredAsync({
    params: ctx.params,
    isLoggedIn: !!currentUser,
    apolloClient,
  });

  const globalProps = getGlobalProps({ ctx, headers, currentUser, isHostingConfigured });

  customRedirects({
    ctx,
    currentUser,
    pageOptions: Page.pageOptions,
  });

  return {
    props: {
      initialApolloState: {},
      experimentationConfig,
      ...globalProps,
      resolvedUrl: ctx.resolvedUrl,
    },
  };
}

/** Create an apollo client instance for server side redirects */
export function createApolloClient(ctx: GetServerSidePropsContext) {
  const headers = ctx?.req?.headers ?? {};

  return createClient({
    headers: getUniversalHttpHeaders(headers),
    initialState: {},
    featureGateQueryParams: getPersistentFeatureGateQueryParams(ctx.query),
  });
}

function getUniversalHttpHeaders(serverHttpHeaders: IncomingHttpHeaders): UniversalHttpHeaders {
  return {
    __universal__: true,
    ...(serverHttpHeaders.cookie !== undefined ? { cookie: serverHttpHeaders.cookie } : null),
    ...(serverHttpHeaders['user-agent'] !== undefined
      ? { 'user-agent': serverHttpHeaders['user-agent'] }
      : null),
  };
}

function parseFeatureGateQueryParameter(value?: string | string[]) {
  if (!value) {
    return [];
  }
  const valueArray = Array.isArray(value) ? value : [value];
  return valueArray.flatMap((v) => v.split(',').map((v) => v.trim()));
}

export function getPersistentFeatureGateQueryParams(query: ParsedUrlQuery): FeatureGateQueryParams {
  return {
    [FEATURE_GATE_ENABLE_PARAM]: parseFeatureGateQueryParameter(query[FEATURE_GATE_ENABLE_PARAM]),
    [FEATURE_GATE_DISABLE_PARAM]: parseFeatureGateQueryParameter(query[FEATURE_GATE_DISABLE_PARAM]),
  };
}

async function getIsHostingConfiguredAsync({
  apolloClient,
  params,
  isLoggedIn,
}: {
  apolloClient: ApolloClient<object>;
  params?: { projectName?: string; account?: string };
  isLoggedIn: boolean;
}) {
  if (!isLoggedIn || !params?.account || !params.projectName) {
    return null;
  }

  const fullName = `@${params.account}/${params.projectName}`;
  let devDomainNameResult;
  try {
    devDomainNameResult = await apolloClient.query<AppDevDomainNameQuery>({
      query: AppDevDomainNameDocument,
      fetchPolicy: 'network-only',
      variables: {
        fullName,
      },
    });
  } catch {
    return false;
  }

  return !!devDomainNameResult?.data.app.byFullName.devDomainName?.name;
}
