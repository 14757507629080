import { mergeClasses } from '@expo/styleguide';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import * as Sentry from '@sentry/react';
import { useState, FormEvent, useRef, useEffect } from 'react';

import { type APIV2Client } from '~/common/api-v2-client';
import { ApiError } from '~/common/errors/ApiError';
import { MINUTE_IN_MS } from '~/common/format-duration';
import * as AuthenticationHandlers from '~/common/handlers/authentication';
import { handleSSOUpgradeSudoParamsAsync } from '~/common/handlers/sso-authentication-flow';
import {
  SSOAuthFlowData,
  SSOAuthRequestData,
  SSO_AUTH_FLOW_KEY,
  getSSOAuthRequestFields,
  saveSSOAuthFlowData,
} from '~/common/sso-auth-flow-manager';
import * as Validations from '~/common/validations';
import { SecondFactorMethod } from '~/graphql/types.generated';
import { BoxContentContainer } from '~/ui/components/Box/BoxContentContainer';
import { Button } from '~/ui/components/Button';
import { OneTimePasswordForm } from '~/ui/components/authentication/OneTimePasswordForm';
import { Form, getFormFieldData } from '~/ui/components/form/Form';
import { FormError } from '~/ui/components/form/FormError';
import { FormGroup } from '~/ui/components/form/FormGroup';
import { FormStates } from '~/ui/components/form/FormStates';
import { Input } from '~/ui/components/form/Input';
import { PasswordInputToggler } from '~/ui/components/form/PasswordInputToggler';
import { CALLOUT } from '~/ui/components/text';

import { createPopup } from './utils';
import { BoxFooter } from '../../Box/BoxFooter';
import { BoxNotice } from '../../Box/BoxNotice';
import { BoxWrapper } from '../../Box/BoxWrapper';
import { DialogContent } from '../../Dialog/DialogContent';
import { DialogPortal } from '../../Dialog/DialogPortal';
import { DialogRoot } from '../../Dialog/DialogRoot';
import { DialogTitle } from '../../Dialog/DialogTitle';
import { PartialUserSecondFactorDevice } from '../OneTimePasswordForm/types';

type SecondFactorDevice = {
  id: string;
  method: 'authenticator' | 'sms';
  sms_phone_number: string | null;
  is_primary: boolean;
};

type SecondFactorDevicesMetadata = { secondFactorDevices: SecondFactorDevice[] };

type RootFormProps = {
  isDialog?: never | false;
  isDialogOpen?: never;
  setIsDialogOpen?: never;
};

type DialogFormProps = {
  isDialog: true;
  isDialogOpen: boolean;
  setIsDialogOpen: (open: boolean) => void;
};

enum AuthStep {
  PASSWORD,
  OTP,
}

export type UpgradeSudoFormProps = {
  apiV2Client: APIV2Client;
  userIsSSO: boolean;
  username: string;
  title?: string | ((step: AuthStep) => string);
  description?: string | ((step: AuthStep) => string);
  disabled?: boolean;
  buttonTitle?: string;
  smsAutoSendDisabled?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
} & (RootFormProps | DialogFormProps);

export function UpgradeSudoForm({
  userIsSSO,
  username,
  title = userIsSSO
    ? 'Re-authenticate'
    : (step) =>
        step === AuthStep.PASSWORD ? 'Confirm your password' : 'Confirm your one-time password',
  description = 'This action requires re-authentication before it is run.',
  buttonTitle = userIsSSO ? 'Continue with SSO' : 'Verify',
  smsAutoSendDisabled = true,
  disabled,
  isDialog,
  isDialogOpen,
  setIsDialogOpen,
  onSuccess,
  onCancel,
  apiV2Client,
}: UpgradeSudoFormProps) {
  const [formState, setFormState] = useState(FormStates.IDLE);
  const [passwordError, setPasswordError] = useState('');
  const [formError, setFormError] = useState('');
  const [authFormData, setAuthFormData] = useState<
    { username: string; password: string } | undefined
  >();
  const [secondFactorDevices, setSecondfactorDevices] = useState<
    PartialUserSecondFactorDevice[] | undefined
  >();
  const [otpInputError, setOTPInputError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(function focusOnMount() {
    passwordInputRef.current?.focus();
  }, []);

  function handleCancel() {
    if (formRef?.current) {
      formRef?.current.reset();
    }

    if (onCancel) {
      onCancel();
    }

    if (setIsDialogOpen) {
      setIsDialogOpen(false);
    }

    setPasswordError('');
    setFormError('');
    setOTPInputError('');
    setSecondfactorDevices(undefined);
    setFormState(FormStates.IDLE);
  }

  async function onSubmitAsync(event: FormEvent) {
    setPasswordError('');
    setFormError('');
    setOTPInputError('');

    const { password, otp = undefined } = getFormFieldData(event);
    setFormState(FormStates.LOADING);

    try {
      if (userIsSSO) {
        const { authorizationUrl, organizationName } =
          await handleSSOUpgradeSudoParamsAsync(apiV2Client);

        const { authUrl, authState, authNonce } = getSSOAuthRequestFields({ authorizationUrl });

        const fiveMinutesFromNow = new Date(Date.now() + MINUTE_IN_MS * 5);
        const expiresAt = fiveMinutesFromNow.toJSON();
        const authRequestData: SSOAuthRequestData = { authState, authNonce };
        const authFlowData: SSOAuthFlowData = {
          organizationName,
          username: undefined,
          redirectUri: window.location.href,
          authConfiguration: { authorizationUrl },
          authRequestData,
          expiresAt,
          sudo: true,
        };

        saveSSOAuthFlowData(SSO_AUTH_FLOW_KEY, authFlowData);

        try {
          await createPopup(authUrl, handleCancel);
        } catch (error) {
          console.error('An error occurred when trying sso upgrade popup method', error);
          window.location.href = authUrl;
        }
      } else {
        if (secondFactorDevices) {
          if (otp.trim().length === 0) {
            setOTPInputError('You must provide a value for the one-time password.');
            setFormState(FormStates.IDLE);
            return;
          }
        } else {
          const invalidPassword = Validations.existingPassword(password);

          if (invalidPassword) {
            setPasswordError(invalidPassword);
            setFormState(FormStates.IDLE);
            return;
          }
        }

        await AuthenticationHandlers.handleUpgradeSudoAsync(apiV2Client, {
          username,
          password,
          otp,
        });
      }

      setFormState(FormStates.SUCCESS);

      if (onSuccess) {
        onSuccess();
      } else {
        event.target.dispatchEvent(event.nativeEvent);
      }

      if (setIsDialogOpen) {
        setIsDialogOpen(false);
      }

      setPasswordError('');
      setFormError('');
      setOTPInputError('');
      setSecondfactorDevices(undefined);
    } catch (error) {
      if (error instanceof ApiError && error.code === 'ONE_TIME_PASSWORD_REQUIRED') {
        const metadata = error.metadata as SecondFactorDevicesMetadata;

        setSecondfactorDevices(
          metadata.secondFactorDevices.map((secondFactorDevice) => ({
            id: secondFactorDevice.id,
            smsPhoneNumber: secondFactorDevice.sms_phone_number,
            method:
              secondFactorDevice.method === 'sms'
                ? SecondFactorMethod.Sms
                : SecondFactorMethod.Authenticator,
            isPrimary: secondFactorDevice.is_primary,
          }))
        );
        setAuthFormData({ username, password });
      } else if (error instanceof ApiError) {
        setFormError(error.message);
      } else {
        Sentry.captureException(error);
        setFormError(`An error occurred while logging in. ${(error as Error).message}`);
      }
    }

    setFormState(FormStates.IDLE);
  }

  async function sendSMSOTPAsync(deviceId: string) {
    if (authFormData) {
      await AuthenticationHandlers.handleSendSMSOTPAsync(apiV2Client, {
        username: authFormData.username,
        password: authFormData.password,
        secondFactorDeviceID: deviceId,
      });
    }
  }

  const currentStep = secondFactorDevices ? AuthStep.OTP : AuthStep.PASSWORD;

  const content = (
    <Form
      ref={formRef}
      onSubmit={onSubmitAsync}
      disabled={formState !== FormStates.IDLE}
      variant={isDialog ? 'flat' : 'shadow'}>
      <BoxContentContainer className="flex flex-col gap-4">
        {description && (
          <CALLOUT>
            {typeof description === 'string' ? description : description(currentStep)}
          </CALLOUT>
        )}
        {!disabled && !userIsSSO && (
          <>
            <div
              className={mergeClasses(
                'flex flex-col gap-4',
                secondFactorDevices != null && 'hidden'
              )}>
              <FormGroup title="Password" htmlFor="password">
                <div className="relative flex flex-1 flex-col gap-2">
                  <Input
                    ref={passwordInputRef}
                    id="password"
                    autoFocus
                    type={passwordVisible ? 'text' : 'password'}
                    autoComplete="current-password"
                    error={passwordError}
                  />
                  <PasswordInputToggler
                    className="top-2"
                    isVisible={passwordVisible}
                    onClick={() => setPasswordVisible((prevVisibility) => !prevVisibility)}
                  />
                </div>
              </FormGroup>
            </div>

            {secondFactorDevices && (
              <OneTimePasswordForm
                nestedForm
                errorMessage={otpInputError}
                secondFactorDevices={secondFactorDevices}
                sendSMSOTPAsync={sendSMSOTPAsync}
                SMSAutoSendDisabled={smsAutoSendDisabled}
              />
            )}
          </>
        )}
        <FormError error={formError} />
      </BoxContentContainer>
      {!disabled ? (
        <BoxFooter className="gap-2">
          <Button
            testID="confPassCancel"
            name="confPassCancel"
            theme="quaternary"
            type="button"
            onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            testID="confPassSubmit"
            name="confPassSubmit"
            theme="primary"
            type="submit"
            rightSlot={userIsSSO ? <ArrowRightIcon /> : undefined}
            status={formState}>
            {buttonTitle}
          </Button>
        </BoxFooter>
      ) : (
        <BoxNotice description="This action requires the Owner role." />
      )}
    </Form>
  );

  if (isDialog) {
    return (
      <DialogRoot
        open={isDialogOpen}
        onOpenChange={(open) => {
          if (!open && onCancel) {
            onCancel();
          }

          setIsDialogOpen(open);
        }}>
        <DialogPortal>
          <DialogContent ignoreOnePasswordFocusClick>
            {title ? (
              <DialogTitle title={typeof title === 'string' ? title : title(currentStep)} />
            ) : (
              <VisuallyHidden asChild>
                <DialogTitle title="Sudo mode upgrade dialog" />
              </VisuallyHidden>
            )}
            {content}
          </DialogContent>
        </DialogPortal>
      </DialogRoot>
    );
  }

  return <BoxWrapper>{content}</BoxWrapper>;
}
