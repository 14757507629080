import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';

import { getAuthCredentials } from '~/common/authentication';
import possibleTypesData from '~/graphql/possibleTypes.generated.json';
import {
  AppleDeviceFilterInput,
  AuditLogFilterInput,
  BranchFilterInput,
  BuildFilterInput,
  ChannelFilterInput,
  DeploymentFilterInput,
} from '~/graphql/types.generated';
import { UniversalHttpHeaders } from '~/types';

import { createKeyArray } from './createKeyArray';
import { FeatureGateQueryParams } from './gating/FeatureGateOverrides';

export const createClient = (options: {
  headers?: UniversalHttpHeaders;
  initialState?: NormalizedCacheObject;
  featureGateQueryParams: FeatureGateQueryParams;
  link?: ApolloLink[];
}) => {
  const GRAPHQL_ORIGIN =
    typeof window !== 'undefined'
      ? process.env.API_SERVER_URL
      : process.env.API_SERVER_INTERNAL_URL;
  const { possibleTypes } = possibleTypesData;

  const url = new URL(`${GRAPHQL_ORIGIN}/graphql`);
  Object.entries(options.featureGateQueryParams).forEach(([param, values]) => {
    if (values.length > 0) {
      url.searchParams.append(param, values.join(','));
    }
  });

  const errorLink = onError(({ operation, graphQLErrors }) => {
    const operationsToIgnore = ['AppExistsByFullName'];

    // Don't report ignored operations to Sentry
    if (operationsToIgnore.includes(operation.operationName)) {
      return;
    }

    Sentry.withScope((scope) => {
      scope.setTransactionName(operation.operationName);
      scope.setContext('apolloGraphQLOperation', {
        operationName: operation.operationName,
        variables: operation.variables,
        extensions: operation.extensions,
      });

      graphQLErrors?.forEach((error) => {
        Sentry.captureMessage(error.message, {
          level: 'error',
          fingerprint: ['{{ default }}', '{{ transaction }}'],
          contexts: {
            apolloGraphQLError: {
              error,
              message: error.message,
              extensions: error.extensions,
            },
          },
        });
      });
    });
  });

  const httpLink = new BatchHttpLink({
    uri: url.toString(),
    credentials: 'same-origin',
    batchInterval: typeof window !== 'undefined' ? 10 : 40,
    batchMax: 20,
  });

  const authMiddlewareLink = setContext((_operation, prevContext) => {
    const authCredentials = getAuthCredentials(options.headers) ?? {};
    const sessionSecret = authCredentials.sessionSecret;

    return {
      ...prevContext,
      headers: {
        ...options?.headers,
        'referrer-policy': 'unsafe-url',
        ...(sessionSecret ? { 'expo-session': JSON.stringify(sessionSecret) } : null),
        ...prevContext.headers,
      },
    };
  });

  const extraLinks = options.link ?? [];
  const link = from([errorLink, authMiddlewareLink, ...extraLinks, httpLink]);

  /**
   * Add/remove keys here to be consistent with BuildFilterInput
   */
  const BuildFilterInputKeys = createKeyArray<BuildFilterInput>({
    channel: null,
    platforms: null,
    releaseChannel: null,
    distributions: null,
    developmentClient: null,
    simulator: null,
    runtimeVersion: null,
  });

  /**
   * Add/remove keys here to be consistent with ChannelFilterInput
   */
  const ChannelFilterInputKeys = createKeyArray<ChannelFilterInput>({
    searchTerm: null,
  });

  /**
   * Add/remove keys here to be consistent with BranchFilterInput
   */
  const BranchFilterInputKeys = createKeyArray<BranchFilterInput>({
    searchTerm: null,
  });

  /**
   * Add/remove keys here to be consistent with DeploymentFilterInput
   */
  const DeploymentFilterInputKeys = createKeyArray<DeploymentFilterInput>({
    channel: null,
    runtimeVersion: null,
  });

  /**
   * Add/remove keys here to be consistent with AppleDeviceFilterInput
   */
  const AppleDeviceFilterInputKeys = createKeyArray<AppleDeviceFilterInput>({
    appleTeamIdentifier: null,
    identifier: null,
    class: null,
  });

  /**
   * Add/remove keys here to be consistent with AuditLogFilterInput
   */
  const AuditLogFilterInputKeys = createKeyArray<AuditLogFilterInput>({
    entityTypes: null,
    mutationTypes: null,
  });

  const cache = new InMemoryCache({
    possibleTypes,
    addTypename: true,
    typePolicies: {
      GitHubAppQuery: {
        keyFields: ['clientIdentifier'],
      },
      App: {
        fields: {
          timelineActivity: relayStylePagination(),
          buildsPaginated: {
            ...relayStylePagination(),
            // relayStylePagination function generates a field policy that simply returns all
            // available data, ignoring args, which makes it easier to use with fetchMore.
            // When we apply filters, we are paginating over a different stream of data and need
            // the cache to store it under a different key.
            keyArgs: ['filter', [...BuildFilterInputKeys]],
          },
          updatesPaginated: relayStylePagination(),
          channelsPaginated: {
            ...relayStylePagination(),
            keyArgs: ['filter', [...ChannelFilterInputKeys]],
          },
          branchesPaginated: {
            ...relayStylePagination(),
            keyArgs: ['filter', [...BranchFilterInputKeys]],
          },
          submissionsPaginated: relayStylePagination(),
          deployments: {
            ...relayStylePagination(),
            keyArgs: ['filter', [...DeploymentFilterInputKeys]],
          },
          icon: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          githubRepository: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          workerDeployments: relayStylePagination(),
          workerDeploymentAliases: relayStylePagination(),
        },
      },
      Account: {
        fields: {
          timelineActivity: relayStylePagination(),
          appsPaginated: relayStylePagination(),
          appleTeamsPaginated: relayStylePagination(),
          appleDistributionCertificatesPaginated: relayStylePagination(),
          applePushKeysPaginated: relayStylePagination(),
          appleProvisioningProfilesPaginated: relayStylePagination(),
          appStoreConnectApiKeysPaginated: relayStylePagination(),
          appleDevicesPaginated: {
            ...relayStylePagination(),
            keyArgs: ['filter', [...AppleDeviceFilterInputKeys]],
          },
          googleServiceAccountKeysPaginated: relayStylePagination(),
          auditLogsPaginated: {
            ...relayStylePagination(),
            keyArgs: ['filter', [...AuditLogFilterInputKeys]],
          },
          billing: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      UserActor: {
        fields: {
          websiteNotificationsPaginated: relayStylePagination(),
        },
      },
      Query: {
        fields: {
          actor: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
          account: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      Mutation: {
        fields: {
          account: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
    },
  });

  return new ApolloClient({
    link,
    cache: options.initialState ? cache.restore(options.initialState) : cache,
    ...(typeof window === 'undefined' ? { ssrMode: true } : {}),
    connectToDevTools: process.env.NODE_ENV !== 'production',
    defaultOptions: {
      watchQuery: {
        // Limit all querys' polling to when the window is focused
        skipPollAttempt: () => typeof window === 'undefined' || document.hidden,
      },
    },
  });
};
