import { mergeClasses } from '@expo/styleguide';
import { ChevronRightIcon } from '@expo/styleguide-icons/outline/ChevronRightIcon';
import {
  Sub,
  SubContent,
  SubTrigger,
  Portal,
  DropdownMenuSubContentProps,
} from '@radix-ui/react-dropdown-menu';
import { ComponentType, HTMLAttributes } from 'react';

import { CALLOUT } from '~/ui/components/text';

type Props = DropdownMenuSubContentProps & {
  label: string;
  Icon?: ComponentType<HTMLAttributes<SVGSVGElement>>;
};

export function DropdownSubMenu({
  label,
  Icon,
  children,
  className,
  sideOffset = 2,
  alignOffset = -5,
  ...rest
}: Props) {
  return (
    <Sub>
      <SubTrigger
        className={mergeClasses(
          'relative flex cursor-pointer select-none items-center justify-between rounded-sm px-2 py-1 outline-0',
          'hocus:bg-hover'
        )}>
        <div className="flex items-center gap-2">
          {Icon && <Icon className="icon-sm" />}
          <CALLOUT>{label}</CALLOUT>
        </div>
        <ChevronRightIcon className="icon-sm text-icon-secondary" />
      </SubTrigger>
      <Portal>
        <SubContent
          className={mergeClasses(
            'z-[606] flex min-w-[220px] flex-col gap-0.5 rounded-md border border-default bg-default p-1 shadow-md',
            className
          )}
          sideOffset={sideOffset}
          alignOffset={alignOffset}
          {...rest}>
          {children}
        </SubContent>
      </Portal>
    </Sub>
  );
}
