import { ApolloClient } from '@apollo/client';
import cookieParser from 'cookie';
import isEmpty from 'lodash/isEmpty';
import { IncomingHttpHeaders } from 'node:http';

import type { APIV2Client } from '~/common/api-v2-client';
import Storage from '~/common/storage';
import { ExperimentationQueryDataFragment } from '~/graphql/fragments/ExperimentationData.fragment.generated';
import {
  CurrentUserDocument,
  CurrentUserQuery,
} from '~/graphql/queries/CurrentUserQuery.query.generated';

export type AuthCredentials = {
  sessionSecret?: {
    id: string;
    expires_at: number;
    version: string;
  };
};

export type AuthHttpHeaders = {
  cookie?: string;
};

export function getAuthStorageKey() {
  return process.env.DEPLOYMENT_ENVIRONMENT === 'production' ? 'io.expo.auth' : 'staging.expo.auth';
}

function parseAuthHeaders(headers?: AuthHttpHeaders): AuthCredentials | null {
  try {
    const keys = ['sessionSecret'];
    const cookie = headers?.cookie as string;
    const parsedCookie = cookieParser.parse(cookie);
    const storageKey = getAuthStorageKey();
    const result = keys.reduce(
      (acc, key) => parseEntry(acc, key, parsedCookie[`${storageKey}.${key}`]),
      {}
    );

    return !isEmpty(result) ? result : null;
  } catch {
    return null;
  }
}

function parseAuthCookie(): AuthCredentials | null {
  const keys = ['sessionSecret'];
  const browserCookie = new Storage(getAuthStorageKey(), 'cookie');

  const result = keys.reduce((acc, key) => parseEntry(acc, key, browserCookie.getItem(key)), {});
  return !isEmpty(result) ? result : null;
}

function parseEntry(acc: object, key: string, value?: string | null) {
  try {
    if (!value) {
      return acc;
    }
    return {
      ...acc,
      [key]: JSON.parse(value),
    };
  } catch {
    return acc;
  }
}

export function getAuthCredentials(headers?: AuthHttpHeaders) {
  const headerCredentials = headers ? parseAuthHeaders(headers) : null;
  const browserCredentials = typeof window !== 'undefined' ? parseAuthCookie() : null;

  return browserCredentials ?? headerCredentials;
}

export function hasAuthCredentials(headers?: AuthHttpHeaders) {
  const parsedHeaders = getAuthCredentials(headers);
  return !!parsedHeaders?.sessionSecret?.id;
}

export async function getCurrentUserAsync(options: {
  apolloClient: ApolloClient<object>;
  headers?: IncomingHttpHeaders;
}): Promise<{
  currentUser: NonNullable<CurrentUserQuery['meUserActor']>;
  experimentation: ExperimentationQueryDataFragment;
} | null> {
  const { apolloClient, headers } = options;

  if (!hasAuthCredentials(headers)) {
    return null;
  }

  try {
    const result = await apolloClient.query<CurrentUserQuery>({
      query: CurrentUserDocument,
    });

    return result.data.meUserActor
      ? {
          currentUser: result.data.meUserActor,
          experimentation: result.data.experimentation,
        }
      : null;
  } catch {
    return null;
  }
}

type RenameUserAndConvertAccountToOrganizationOptions = {
  newUsername: string;
};

export async function renameUserAndConvertAccountToOrganizationAsync(
  apiV2Client: APIV2Client,
  options: RenameUserAndConvertAccountToOrganizationOptions
) {
  const { newUsername } = options;
  return await apiV2Client.sendAuthenticatedApiV2RequestAsync<{
    accountId: string;
    newUsernameForPreviousImplicitOwner: string;
  }>('auth/rename-user-and-convert-to-organization', {
    body: { newUsername },
  });
}
