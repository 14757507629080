import { useContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';

import { FeatureGateContext, defaultGate } from '../FeatureGateContext';
import { Tier } from '../Tier';

export class ChannelPausingFeatureGate extends FeatureGate {
  protected getExperimentKey(): FeatureGateKey {
    return FeatureGateKey.CHANNEL_PAUSING;
  }

  protected getFullyRolledOutTiers(): ReadonlySet<Tier> {
    return new Set([Tier.DEVELOPMENT, Tier.STAGING]);
  }
}

export function useChannelPausingFeatureGate() {
  const context = useContext(FeatureGateContext);
  return context?.[FeatureGateKey.CHANNEL_PAUSING] ?? defaultGate;
}
